<template>
  <van-list
    v-model:loading="loading"
    :finished="finished"
    finished-text="没有更多了"
    @load="getProducts"
  >
    <van-checkbox-group v-model="result" @change="onClick">
      <template v-for="item in items" :key="item">
        <van-row>
          <van-col span="4">
            <van-checkbox :name="item.productId" style="text-align:center;margin:35px 0 0 15px;"></van-checkbox>
          </van-col>
          <van-col span="20">
            <van-card
              :price="item.price"
              :title="item.name"
              :thumb="item.image"
            >
            </van-card>
          </van-col>
        </van-row>
      </template>
    </van-checkbox-group>
  </van-list>
</template>

<script>
import { reactive, toRefs, onMounted, inject, watch } from 'vue'

export default {
  props: {
    sort: {
      type: String,
      default: 'productId ASC'
    },
    tag: {
      type: Number
    },
    title: {
      type: String
    },
    multiple: {
      type: Boolean,
      default: true
    },
    selected: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  setup (props, context) {
    const post = inject('post')
    const Cookies = inject('Cookies')
    const state = reactive({
      storeId: Cookies.get('storeId'),
      page: 1,
      finished: false,
      loading: false,
      items: [],
      result: props.selected
    })
    const onClick = (values) => {
      context.emit('select', values)
    }
    const init = () => {
    }
    const getProducts = () => {
      post('/product.list', {
        pageNum: state.page,
        storeId: state.storeId,
        sort: props.sort,
        tagId: props.tag,
        name: props.title,
        onSale: true,
        bizScope: 'GENERAL'
      }).then(res => {
        state.items = [...state.items, ...res.data.content]
        state.loading = false
        state.finished = res.data.last
        state.page++
      })
    }
    watch(() => state.result, (to, previous) => {
    })
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      getProducts,
      onClick
    }
  }
}
</script>
