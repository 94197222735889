<template>
  <van-nav-bar
    title="首页装修"
    left-text="清空"
    right-text="保存"
    @click-right="onSaveAll"
    @click-left="onClearAll"
  />
  <template v-if="page.items.length > 0">
    <template v-for="(item, index) in page.items" :key="item">
      <div class="box">
        <div class="cfg">
          <van-button icon="plus" type="primary" size="small" @click="onAdd(index)" />&nbsp;
          <van-button icon="cross" type="primary" size="small" @click="onDelete(index)" />&nbsp;
          <van-button icon="arrow-up" type="primary" size="small" @click="onUp(index)" />&nbsp;
          <van-button icon="arrow-down" type="primary" size="small" @click="onDown(index)" />&nbsp;
          <van-button icon="setting-o" type="primary" size="small" @click="onSet(item, index)" />
        </div>
        <template v-if="item.name === 'image'">
          <wg-image :box="item" />
        </template>
        <template v-if="item.name === 'swipe'">
          <wg-swipe :box="item" />
        </template>
        <template v-if="item.name === 'video'">
          <wg-video :box="item" />
        </template>
        <template v-if="item.name === 'product'">
          <wg-product :box="item" />
        </template>
      </div>
    </template>
    <van-popup v-if="sonRefresh" v-model:show="set.show" position="bottom" :style="{ height: '80%', width: '100%', background: '#fff' }">
      <template v-if="set.cur.name === 'image'">
        <wg-image-config :box="set.cur" @save="onSaveItem" />
      </template>
      <template v-if="set.cur.name === 'swipe'">
        <wg-swipe-config :box="set.cur" @save="onSaveItem" />
      </template>
      <template v-if="set.cur.name === 'video'">
        <wg-video-config :box="set.cur" @save="onSaveItem" />
      </template>
      <template v-if="set.cur.name === 'product'">
        <wg-product-config :box="set.cur" @save="onSaveItem" />
      </template>
    </van-popup>
  </template>
  <template v-else>
    <div style="margin:15px;">
      <van-button block @click="onAdd(0)">创建第一个模块</van-button>
    </div>
  </template>
  <van-action-sheet v-model:show="show" :actions="actions" @select="onSelect" />
</template>

<script>
import { toRefs, reactive, onMounted, inject, watch, nextTick } from 'vue'
import { WgImage, WgImageConfig, WgSwipe, WgSwipeConfig, WgVideo, WgVideoConfig, WgProduct, WgProductConfig } from './widget'
import { Toast, Dialog } from 'vant'
import boxItems from './box'

export default {
  components: {
    WgImage,
    WgImageConfig,
    WgSwipe,
    WgSwipeConfig,
    WgVideo,
    WgVideoConfig,
    WgProduct,
    WgProductConfig
  },
  setup () {
    const post = inject('post')
    const Cookies = inject('Cookies')
    const state = reactive({
      storeId: Cookies.get('storeId'),
      sonRefresh: true,
      set: {
        show: false,
        cur: {},
        index: 0
      },
      show: false,
      actions: boxItems,
      page: {
        items: [
          {
            name: 'product',
            alias: '商品',
            param: {
              style: 'margin:0 0 0 0;',
              ids: [1, 2, 3, 5],
              column: '1'
            }
          }
        ]
      },
      store: {}
    })
    const onSaveAll = () => {
      post('/store.update', {
        ...state.store,
        page: JSON.stringify(state.page)
      }).then(res => {
        if (res.code === 0) {
          Toast.success()
        } else {
          Toast(res.msg)
        }
      })
    }
    const onClearAll = () => {
      Dialog.confirm({
        title: '提示',
        message: '确认这么做吗？'
      }).then(() => {
        state.page.items = []
      }).catch(() => {
      })
    }
    const onRefresh = () => {
      state.sonRefresh = false
      nextTick(() => {
        state.sonRefresh = true
      })
    }
    const onSet = (item, index) => {
      onRefresh()
      state.set.cur = item
      state.set.show = true
      state.set.index = index
    }
    const onSaveItem = (item) => {
      state.page.items[state.set.index] = item
      state.set.cur = {}
      state.set.show = false
      state.set.index = 0
    }
    const onAdd = (index) => {
      state.show = true
      state.set.index = index
    }
    const onSelect = (item) => {
      state.show = false
      state.page.items.splice(state.set.index, 0, item.obj)
    }
    const onUp = (index) => {
      if (index !== 0) {
        state.page.items[index] = state.page.items.splice(index - 1, 1, state.page.items[index])[0]
      } else {
        state.page.items.push(state.page.items.shift())
      }
    }
    const onDown = (index) => {
      if (index !== state.page.items.length - 1) {
        state.page.items[index] = state.page.items.splice(index + 1, 1, state.page.items[index])[0]
      } else {
        state.page.items.unshift(state.page.items.splice(index, 1)[0])
      }
    }
    const onDelete = (index) => {
      state.page.items.splice(index, 1)
    }
    watch(() => state.page, (to, previous) => {
    }, { deep: true })
    const init = () => {
      post('/store.get', {
        storeId: state.storeId
      }).then(res => {
        state.page = res.data.page !== '' && res.data.page !== '{}' ? JSON.parse(res.data.page) : { items: [] }
        state.store = res.data
      })
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      onClearAll,
      onSaveAll,
      onSet,
      onUp,
      onDown,
      onAdd,
      onDelete,
      onSelect,
      onSaveItem
    }
  }
}
</script>

<style scoped>
.box {
  border:1px dashed #ff6600;
  margin:10px;
  position:relative;
  min-height: 50px;
}
.box .cfg {
  z-index: 999;
  position:absolute;
  top:5px;
  right:5px;
}
</style>
