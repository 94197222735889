<template>
  <van-row>
    <template v-for="item in box.items" :key="item">
      <van-col :span="24 / box.items.length">
        <router-link :to="item.link">
          <van-image width="100%" :src="item.url" />
        </router-link>
      </van-col>
    </template>
  </van-row>
</template>

<script>
import { toRefs, reactive, onMounted } from 'vue'

export default {
  components: {
  },
  props: {
    box: Object
  },
  setup (props, { emit }) {
    const state = reactive({
    })
    const init = () => {
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state)
    }
  }
}
</script>

<style scoped>
.van-image {
  display: inline;
}
</style>
