export default [
  {
    name: '图片盒子',
    obj: {
      name: 'image',
      alias: '图片',
      param: {
        style: 'margin:0 0 0 0;'
      },
      items: []
    }
  },
  {
    name: '轮播盒子',
    obj: {
      name: 'swipe',
      alias: '轮播',
      param: {
        style: 'margin:0 0 0 0;'
      },
      items: []
    }
  },
  {
    name: '视频盒子',
    obj: {
      name: 'video',
      alias: '视频',
      param: {
        style: 'margin:0 0 0 0;',
        url: '',
        poster: '',
        autoplay: ''
      }
    }
  },
  {
    name: '商品盒子',
    obj: {
      name: 'product',
      alias: '普通商品',
      param: {
        style: 'margin:15px 0 0 0;',
        ids: [],
        column: '1'
      }
    }
  }
]
