<template>
  <van-cell title="视频盒子" label="最多支持5张图片" :border="false" />
  <van-form @submit="onSubmit">
    <van-field name="radio" label="排列方式">
      <template #input>
        <van-radio-group v-model="cur.param.column" direction="horizontal">
          <van-radio name="1">单列</van-radio>
          <van-radio name="2">双列</van-radio>
        </van-radio-group>
      </template>
    </van-field>
    <van-field
      readonly
      clickable
      label="选择商品"
      v-model="sk"
      placeholder="点击选择"
      @click="product.show = true"
    />
    <div style="margin: 16px;">
      <van-button round block type="primary" native-type="submit">
        提交
      </van-button>
    </div>
  </van-form>
  <van-popup v-model:show="product.show" position="right" :style="{ height: '100%', width: '80%', background: '#fff' }">
    <product-multiple :selected="cur.param.ids" @select="onSelect" />
  </van-popup>
</template>

<script>
import { toRefs, reactive, onMounted } from 'vue'
import ProductMultiple from './fragment/ProductMultiple'

export default {
  components: {
    ProductMultiple
  },
  props: {
    box: Object
  },
  emits: ['save'],
  setup (props, { emit }) {
    const state = reactive({
      cur: JSON.parse(JSON.stringify(props.box)),
      product: {
        show: false
      },
      sk: ''
    })
    const onSelect = (ids) => {
      state.cur.param.ids = ids
    }
    const onSubmit = (values) => {
      emit('save', state.cur)
    }
    const init = () => {
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      onSubmit,
      onSelect
    }
  }
}
</script>
