<template>
  <van-cell title="图片盒子" label="最多支持4张图片" :border="false" />
  <div style="margin:15px;">
    <van-uploader v-model="images" :max-count="4" :after-read="afterRead" @delete="onDeleteImage"/>
    <template v-for="(item, index) in cur.items" :key="item">
      <h5 style="padding:15px;">图 {{ index + 1 }}</h5>
      <van-field
        readonly
        clickable
        label="链接对象"
        :model-value="item.alias"
        placeholder="请选择图片连接方式"
        @click="link.show = true; itemsIndex = index"
      />
      <van-field v-show="item.name === 'outer' || item.name === 'maps'" v-model="cur.items[index].link" label="链接地址" placeholder="http或https开头" />
      <van-field
        v-show="item.name === 'product'"
        readonly
        clickable
        label="选择商品"
        v-model="item.description"
        placeholder="请选择商品"
        @click="product.show = true; itemsIndex = index"
      />
    </template>
    <van-button round block type="primary" @click="onConfirm">
      提交
    </van-button>
  </div>
  <van-popup v-model:show="product.show" position="right" :style="{ height: '100%', width: '80%', background: '#fff' }">
    <product-single @select="onSelectProduct"/>
  </van-popup>
  <van-action-sheet v-model:show="link.show" :actions="link.items" @select="onSelectLink" />
</template>

<script>
import { toRefs, reactive, onMounted, watch, inject } from 'vue'
import ProductSingle from './fragment/ProductSingle'
import linkItems from './link'
import { getQiniuToken } from '@/api/util'
import { upload } from '@/util/util'

export default {
  components: {
    ProductSingle
  },
  props: {
    box: Object
  },
  emits: ['save'],
  setup (props, { emit }) {
    const Cookies = inject('Cookies')
    const state = reactive({
      token: Cookies.get('token'),
      product: {
        show: false
      },
      link: {
        items: linkItems,
        show: false
      },
      show: false,
      cur: JSON.parse(JSON.stringify(props.box)),
      images: [],
      itemsIndex: 0
    })
    const onConfirm = () => {
      emit('save', state.cur)
    }
    const onDeleteImage = (file, detail) => {
      state.cur.items = state.cur.items.filter((v, i) => i !== detail.index)
    }
    const onSelectProduct = (item) => {
      state.cur.items[state.itemsIndex] = Object.assign(state.cur.items[state.itemsIndex], {
        link: '/product/detail?productId=' + item.productId,
        description: item.name
      })
      state.product.show = false
    }
    const afterRead = (file) => {
      getQiniuToken({}).then(res => {
        if (res.code === 0) {
          upload({
            fileBlob: file.file,
            token: res.data.token,
            domain: res.data.domain
          }).then(res => {
            state.cur.items = state.cur.items.concat(
              [
                {
                  url: res.img,
                  link: ''
                }
              ]
            )
          })
        }
      })
    }
    const onSelectLink = (item) => {
      state.cur.items[state.itemsIndex] = Object.assign(state.cur.items[state.itemsIndex], item.obj)
      if (item.obj.name === 'product') {
        state.product.show = true
      }
      state.link.show = false
    }
    const onCloseSet = () => {
      state.show = false
    }
    watch(() => state.show, (to, previous) => {
    })
    const init = () => {
      for (const item of state.cur.items) {
        state.images.push({
          url: item.url,
          isImage: true
        })
      }
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      onCloseSet,
      onConfirm,
      onSelectLink,
      onSelectProduct,
      afterRead,
      onDeleteImage
    }
  }
}
</script>
