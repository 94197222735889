import request from '@/util/request'

export function getQiniuToken (data) {
  return request({
    url: '/util.getQiniuToken',
    method: 'post',
    data
  })
}

export function sendCaptcha (data) {
  return request({
    url: '/util.sendCaptcha',
    method: 'post',
    data
  })
}

export function getLogistics (data) {
  return request({
    url: '/util.getLogistics',
    method: 'post',
    data
  })
}

export function withdrawal (data) {
  return request({
    url: '/general.withdrawal',
    method: 'post',
    data
  })
}

export function getBlod (url, data) {
  return request({
    url: url,
    method: 'get',
    params: data,
    responseType: 'blob'
  })
}
