<template>
  <van-cell title="视频盒子" label="最多支持5张图片" :border="false" />
  <van-form @submit="onSubmit">
    <van-field name="uploader" label="视频封面">
      <template #input>
        <van-uploader v-model="images" :max-count="1" :after-read="afterRead" />
      </template>
    </van-field>
    <van-field
       v-model="cur.param.url"
      name="url"
      label="视频地址"
      placeholder="http或https开头"
    />
    <van-field name="autoplay" label="开关">
      <template #input>
        <van-switch v-model="cur.param.autoplay" size="20" />
      </template>
    </van-field>
    <div style="margin: 16px;">
      <van-button round block type="primary" native-type="submit">
        提交
      </van-button>
    </div>
  </van-form>
</template>

<script>
import { toRefs, reactive, onMounted, watch } from 'vue'
import { getQiniuToken } from '@/api/util'
import { upload } from '@/util/util'

export default {
  components: {
  },
  props: {
    box: Object
  },
  emits: ['save'],
  setup (props, { emit }) {
    const state = reactive({
      cur: JSON.parse(JSON.stringify(props.box)),
      images: []
    })
    const onSubmit = (values) => {
      state.cur.param = Object.assign(state.cur.param, {
        url: values.url,
        autoplay: values.autoplay,
        poster: values.uploader[0]?.url ?? ''
      })
      emit('save', state.cur)
    }
    const onDeleteImage = (file, detail) => {
      state.cur.param.poster = ''
    }
    const afterRead = (file) => {
      getQiniuToken({}).then(res => {
        if (res.code === 0) {
          upload({
            fileBlob: file.file,
            token: res.data.token,
            domain: res.data.domain
          }).then(res => {
            file.url = res.img
          })
        }
      })
    }
    watch(() => state.show, (to, previous) => {
    })
    const init = () => {
      if (state.cur.param.poster !== '') {
        state.images.push({
          url: state.cur.param.poster,
          isImage: true
        })
      }
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      onSubmit,
      afterRead,
      onDeleteImage
    }
  }
}
</script>
