export default [
  {
    name: '商品',
    obj: {
      name: 'product',
      alias: '商品',
      link: ''
    }
  },
  {
    name: '我的订单',
    obj: {
      name: 'order',
      alias: '我的订单',
      link: '/order/list'
    }
  },
  {
    name: '购物车',
    obj: {
      name: 'cart',
      alias: '购物车',
      link: '/cart'
    }
  },
  {
    name: '团购',
    obj: {
      name: 'groupon',
      alias: '团购',
      link: '/groupon/product/list'
    }
  },
  {
    name: '店铺位置',
    obj: {
      name: 'maps',
      alias: '店铺位置',
      link: ''
    }
  },
  {
    name: '外部链接',
    obj: {
      name: 'outer',
      alias: '外部链接',
      link: ''
    }
  }
]
