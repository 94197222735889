<template>
  <div id="vs"></div>
</template>

<script>
import { toRefs, reactive, onMounted } from 'vue'
import Player from 'xgplayer'

export default {
  props: {
    box: Object
  },
  setup (props, context) {
    const state = reactive({
      p: {}
    })
    const init = () => {
      state.p = new Player({
        id: 'vs',
        url: props.box.param.url,
        poster: props.box.param.poster,
        autoplay: props.box.param.autoplay,
        fluid: true
      }).once('ready', () => {
      })
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state)
    }
  }
}
</script>
